import { Upload, Button } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { Image } from "antd";
import { uploadImage } from "../../../Api/manager";
import { useState,useEffect } from "react";
import ImgCrop from 'antd-img-crop';
const ImageUpload = ({value,onChange,aspect}) => {
    const [url,setUrl] = useState("")
    const uploadAction = async (file) => {
        uploadImage(file.file).then(res=>{
            setUrl(res.data.image_url)
            file.onSuccess(res)
            onChange?.(res.data.image_url)
        })
    }
    useEffect(()=>{
        setUrl(value)
    },[value])
    return <ImgCrop rotationSlider aspect={aspect}>
        <Upload multiple={false} maxCount={1} accept="image/*" customRequest={uploadAction}>
            <Image src={url} alt="image" />
            <Button icon={<UploadOutlined/>}> {value?"修改":"上传"}</Button>
    </Upload>
    </ImgCrop>
}

export default ImageUpload;